<template>
    <DataTable :value="configurations" lazy ref="dt" dataKey="id" :autoLayout="true" @page="onPage($event)"
        showGridlines>
        <Column field="id" header="ID" class="text-center text-xl">
            <template #body="slotProps">
                {{ slotProps.data.id }}
            </template>
        </Column>
        <Column field="name" header="Fornecedor" class="text-center text-xl">
            <template #body="slotProps">
                <div class="flex flex-column">
                    <Badge v-for="vendor in slotProps.data.vendors" :key="vendor.id" class="mt-2"
                        style="font-size: 1rem" :severity="`${changeStatusBoleanToColor(vendor.active)}`"
                        :value="vendor.name" />
                </div>
            </template>
        </Column>
        <Column field="withdraw_enabled=" header="Saque" class="text-center text-xl">
            <template #body="slotProps">
                <div class="flex flex-column">
                    <span>
                        <span>
                            <Badge class="mt-2 mb-2" style="font-size: 1rem"
                                :severity="`${changeStatusBoleanToColor(slotProps.data.withdraw_enabled)}`"
                                :value="changeStatusBoleanToText(slotProps.data.withdraw_enabled)" />
                        </span>
                    </span>
                    <span>
                        Limite Diário: {{ formatToBrl(slotProps.data.daily_withdraw_amount) }}
                    </span>
                    <span>
                        Quantidade por Dia: {{ slotProps.data.daily_number_withdraw }}
                    </span>
                    <span>
                        Limite Diário por CPF: {{ formatToBrl(slotProps.data.daily_withdraw_amount_per_cpf) }}
                    </span>
                    <span>
                        Quantidade Diária por CPF: {{ slotProps.data.daily_number_withdraw_per_cpf }}
                    </span>
                    <span>
                        Mínimo: {{ formatToBrl(slotProps.data.min_withdraw_value) }}
                    </span>
                    <span>
                        Máximo: {{ formatToBrl(slotProps.data.max_withdraw_value) }}
                    </span>
                </div>
            </template>
        </Column>
        <Column field="deposit_enabled=" header="Depósito" class="text-center text-xl">
            <template #body="slotProps">
                <div class="flex flex-column">
                    <span>
                        <Badge class="mt-2 mb-2" style="font-size: 1rem"
                            :severity="`${changeStatusBoleanToColor(slotProps.data.deposit_enabled)}`"
                            :value="changeStatusBoleanToText(slotProps.data.deposit_enabled)" />
                    </span>
                    <span>
                        Limite Diário: {{ formatToBrl(slotProps.data.daily_deposit_amount) }}
                    </span>
                    <span>
                        Quantidade por Dia: {{ slotProps.data.daily_number_deposit }}
                    </span>
                    <span>
                        Limite Diário por CPF: {{ formatToBrl(slotProps.data.daily_deposit_amount_per_cpf) }}
                    </span>
                    <span>
                        Quantidade Diária por CPF: {{ slotProps.data.daily_number_deposit_per_cpf }}
                    </span>
                    <span>
                        Mínimo: {{ formatToBrl(slotProps.data.min_deposit_value) }}
                    </span>
                    <span>
                        Máximo: {{ formatToBrl(slotProps.data.max_deposit_value) }}
                    </span>
                </div>
            </template>
        </Column>
        <!-- <Column field="manual_withdraw" header="Saque Manual" class="text-center text-xl">
            <template #body="slotProps">
                <Badge class="mt-2 mb-2" style="font-size: 1rem"
                    :severity="`${changeStatusBoleanToColor(slotProps.data.manual_withdraw)}`"
                    :value="changeStatusBoleanToText(slotProps.data.manual_withdraw)" />
            </template>
        </Column> -->
        <Column class="text-center text-xl">
            <template #header>
                Ações
            </template>
            <template #body="slotProps">
                <div class="container-button">
                    <Button type="button" icon="pi pi-pencil" @click="openDetailsModalUpdate(slotProps.data)"
                        v-tooltip.bottom="{ value: 'Editar', showDelay: 1000, hideDelay: 300 }" />
                    <Button class="ml-2" type="button" icon="pi pi-clone" @click="openDetailsModalClone(slotProps.data)"
                        v-tooltip.bottom="{ value: 'Clonar Configuração', showDelay: 1000, hideDelay: 300 }" />
                    <Button class="ml-2" type="button" icon="pi pi-list" @click="openPriorityModalSelector()"
                        v-tooltip.bottom="{ value: 'Priorizar Fornecedores', showDelay: 1000, hideDelay: 300 }" />
                    <Button class="ml-2 p-button-danger" type="button" icon="pi pi-trash"
                        @click="openDeleteModal(slotProps.data)"
                        v-tooltip.bottom="{ value: 'Excluir Configuração', showDelay: 1000, hideDelay: 300 }" />
                </div>
            </template>
        </Column>
        <template #empty>
            <div class="text-center mt-8">
                <h3>Nenhum Fornecedor Registrado</h3>
                <Button @click="goToRegisterClient()">Registrar Fornecedor</Button>
            </div>
        </template>
    </DataTable>

    <Dialog v-model:visible="state.detailsModalUpdate" modal class="absolute">

        <div class="col-12 mb-2 text-center">
            <ConfigurationForm :title="`Atualizando Configuração: ${client.name}`" :configurationToUpate="state.config"
                :client="client" :isUpdanting="true" @closeUpdateModal="closeModal" />
        </div>

        <br>
    </Dialog>

    <Dialog v-model:visible="state.detailsModalClone" modal class="absolute">

        <div class="col-12 mb-2 text-center">
            <ConfigurationForm :title="`Clonando Configuração: ${client.name}`" :configurationToUpate="state.config"
                :client="client" :isCloning="true" @closeUpdateModal="closeModal" />
        </div>

        <br>
    </Dialog>

    <Dialog v-model:visible="state.priorityModalSelector" modal class="absolute">

        <div class="col-12 mb-2 text-center">
            <PriorityConfigurationsVendorSelector :configurations="configurations" @closeUpdateModal="closeModal" />
        </div>

        <br>
    </Dialog>

    <Dialog v-model:visible="state.deleteModal" modal class="absolute">

        <Message severity="error" sticky :closable="false">
            Tem certeza que deseja excluir a configuração??
        </Message>

        <Message severity="warn" sticky :closable="false">
            Antes de exluir a configuração, limpe todos os Fornecedores.
        </Message>


        <div class="text-center font-bold flex justify-content-center">

            <Button class="ml-2" :loading="false" type="button" @click="tryToDelete">
                Deletar Configuração
            </Button>

        </div>

    </Dialog>

</template>

<script>

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Badge from 'primevue/badge'
import Dialog from 'primevue/dialog'
import Message from 'primevue/message'
import ConfigurationForm from './ConfigurationForm.vue'
import PriorityConfigurationsVendorSelector from './PriorityConfigurationsVendorSelector.vue'
import { reactive } from 'vue'
import useNotificationToast from '../../../composables/useNotificationToast'
import { useStore } from 'vuex'
import services from '../../../services'

export default {

    inheritAttrs: false,

    components: { DataTable, Column, Button, Badge, Dialog, ConfigurationForm, PriorityConfigurationsVendorSelector, Message },

    props: {
        configurations: {
            type: Array
        },
        client: {
            type: Object
        }
    },

    emits: ['goToRegisterClient', 'realoadConfigurations'],

    setup(props, context) {

        const state = reactive({
            config: null,
            modifiedConfig: null,
            detailsModalUpdate: null,
            detailsModalClone: null,
            priorityModalSelector: null,
            deleteModal: null,
            configurationToDelete: null,
            loading: {
                delete: false
            }
        })

        const { success, error } = useNotificationToast()
        const store = useStore()

        const real = Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        })

        function changeStatusBoleanToColor(active) {
            return active ? 'success' : 'danger'
        }

        function changeStatusBoleanToText(active) {
            return active ? 'Ativado' : 'Desativado'
        }

        function formatToBrl(amount) {
            return real.format(amount)
        }

        function openDetailsModalUpdate(config) {
            state.config = config
            state.modifiedConfig = { ...config }
            state.detailsModalUpdate = true
        }

        function openDetailsModalClone(config) {
            state.config = config
            state.modifiedConfig = { ...config }
            state.detailsModalClone = true
        }

        function openPriorityModalSelector() {

            state.priorityModalSelector = true

        }

        function openDeleteModal(configurationToDelete) {

            state.configurationToDelete = configurationToDelete

            if (configurationToDelete.vendors.length > 0) {

                error("Limpe os fornecedores antes de deletar a configuração!")
                return
            }

            state.deleteModal = true

        }

        function goToRegisterClient() {
            context.emit('goToRegisterClient')
        }

        function closeModal() {
            state.detailsModalUpdate = false
            context.emit('realoadConfigurations')
        }

        function tryToDelete() {

            const token = store.getters.getToken

            state.loading.delete = true

            services.configurations.delete({ configurationId: state.configurationToDelete.id, token })
                .then(() => {

                    success('Configuração deletada com sucesso!')

                    state.deleteModal = false

                    context.emit('realoadConfigurations')

                })
                .catch(() => {

                    error("Não foi possível deletar configurações.")

                })
                .finally(() => { state.loading.delete = false })
        }



        return {
            state,
            changeStatusBoleanToColor,
            changeStatusBoleanToText,
            formatToBrl,
            openDetailsModalUpdate,
            openDetailsModalClone,
            openPriorityModalSelector,
            openDeleteModal,
            goToRegisterClient,
            closeModal,
            tryToDelete
        }

    }
}
</script>