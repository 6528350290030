<template>

    <h3 class="text-center "> Priorização da Ordem de Configurações</h3>

    <Message style="width: 500px;" severity="info" sticky :closable="false">

        <p class="text-lg">A prioridade é definida pela ordem de cima pra baixo.Pode definir tanto a configuração
            que vai ser priorizada, e dentro da configuração pode também pode
            definir qual fornecedor vai ser priorizado. E só clicar, arrastar e organizar a prioridade que você deseja.
        </p>


    </Message>


    <Draggable v-model="state.sortedPriorityConfigurations"
        class="drop-zone drop-zone-configurations border-radius border-round-xl">
        <template #item="{ element: configuration }">

            <div class="py-2 px-3 w-full cursor-pointer drop-element border-round-xl">

                ID da Configuração: {{ configuration.id }}

                <div class="flex flex-column mt-2">

                    <Draggable v-model="configuration.vendors" class="drop-zone drop-zone-vendors border-round-xl">

                        <template #item="{ element: vendor }">
                            <Badge class="mt-2" style="font-size: 1rem"
                                :severity="`${changeStatusBoleanToColor(vendor.active)}`" :value="vendor.name"
                                draggable="true" />
                        </template>

                    </Draggable>

                </div>
            </div>
        </template>
    </Draggable>

    <Button class="mt-4" :loading="state.updatingPriority" :label="'Priorizar ordem Configuração'" icon="pi pi-check"
        iconPos="right" :disabled="state.updatingPriority" @click="updatePriority" />
</template>

<script>
import Badge from 'primevue/badge'
import { reactive } from 'vue'
import Draggable from 'vuedraggable'
import { useStore } from 'vuex'
import useNotificationToast from '../../../composables/useNotificationToast'
import services from '../../../services'
import Button from 'primevue/button'
import Message from 'primevue/message'

export default {

    props: {
        configurations: {
            type: Array
        }
    },

    components: {
        Draggable,
        Badge,
        Button,
        Message
    },

    setup(props, context) {

        const state = reactive({
            sortedPriorityConfigurations: [],
            updatingPriority: false
        })

        state.sortedPriorityConfigurations = JSON.parse(JSON.stringify(props.configurations))

        const store = useStore()
        const { success, error } = useNotificationToast()

        function changeStatusBoleanToColor(active) {
            return active ? 'success' : 'danger'
        }

        function changeStatusBoleanToText(active) {
            return active ? 'Ativado' : 'Desativado'
        }

        function startDrag(event, item) {
            event.dataTransfer.dropEffect = 'move'
            event.dataTransfer.effectAllowed = 'move'
            event.dataTransfer.setData('itemID', item.id)
        }

        function onDrop(event, list) {
            const itemID = event.dataTransfer.getData('itemID')
            const item = props.configurations.find(configuration => configuration.id == itemID)
            item.list = list
        }

        async function updatePriority() {

            state.updatingPriority = true

            const token = store.getters.getToken

            await services.configurations.prioritize({ newPriorityConfigurationsOrder: state.sortedPriorityConfigurations, token })
                .then(() => {
                    success('Configuração atualizada com sucesso!')
                    context.emit('closeUpdateModal')
                })
                .catch(() => {
                    error("Não foi possível atualizar as prioridades de configuração.")
                })
                .finally(() => { state.updatingPriority = false })
        }

        return {
            state,
            changeStatusBoleanToColor,
            changeStatusBoleanToText,
            startDrag,
            onDrop,
            updatePriority
        }


    }

}
</script>

<style scoped>
.drop-zone {
    /* width: 100%; */

    padding: 15px;
    /* height: 400px; */
    /* background-color: #F0EBE3; */
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.drop-zone-configurations {
    background-color: #F0EBE3;
}

.drop-zone-vendors {
    /* background-color: #F0EBE3; */
    background-color: #FFF;
    border: 1px solid;
    border-color: #808080;
}

.drop-zone>*:not(:nth-child(1)) {
    margin-top: 16px;
}

.drop-element {
    width: 100px;
    /* background-color: #3498DB; */
    /* background-color: #808080; */
    background-color: #FFF;
    color: black;
    /* border: 1px solid;
    border-color: #808080; */
    box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.2);
}
</style>